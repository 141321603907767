/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import styles from "./styles.css";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { carDetailUrlAu } from "../../../utils/helpers/get-detail-page-url";
import withWishlist from "../with-wishlist";
import IconHeart from "../../shared/icon-heart";
import CloseButton from "../../shared/icon-cross";
import SoldBadge from "../../shared/images/sold-banner.png";
import WhiteTickIcon from "./../../shared/images/white-tick.svg";
import InfoBlue from "./images/info-icon-blue.svg";
import Tooltip from "../../shared/tooltip";
import { getTooltipInfoList, getPersonalisedTooltipInfoList } from "../../../utils/helpers/car-properties";
import withLogin from "../with-login";
import TrackableCarCardItem from "../trackable-car-card-item";
import carListingEventDataParser, { parseFilterToDimension } from "../../../tracking/parser/car-listing";
import carListingEventDataParserGA4, { parseFilterToDimensionGA4 } from "../../../tracking/parser/car-listing-ga4";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import InfoIcon from "./images/info-icon-white.svg";
import InfoOutline from "./images/info-outline.svg";
import ComingSoon from "./images/coming-soon.svg";
import ThreeSixtyDegree from "./images/three-sixty-degree.svg";
import { cmsURl } from "../../../constants/url-constants";
import { smallImage } from "../../../constants/image-constant";
import LazyImage from "../../shared/lazy-image";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import Truck from "./images/truck-icon.svg";
import Lockimg from "./images/lock.svg";
import loadable from "@loadable/component";
import {  NUMBER, PAGE_SOURCE, SALE_KEY, SPECIALPAGETYPE } from "../../../constants/app-constants";
import DiscountIcon from "./images/discount-icon.svg";
import Button from "../../shared/button";
const EGCPriceView = loadable(() => import("../egc-price-view"));
import TrendingImage from "./images/trending-down.svg";
import KbbPriceScreen from "../../shared/kbb-price-screen/component";
import EndToday from "./images/ends-today.svg";
import { FB_EVENTS_CATEGORY } from "../../../tracking/fb-events";
import carListingAmplitude from "../../../tracking/parser/car-listing-amplitude";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import getQueryParamsSearch from "../../../utils/helpers/get-query-params-search";
import getCarInfo from "../../../utils/helpers/get-car-info";
import TestDriveBadge from "./images/car.png";
import { GUEST_POPUP_TYPES, LOGIN_SOURCE } from "../../../au-c2b/constants/app-constants";
import { LOGIN_TYPE } from "../../../constants/checkout-constants";
import ArrowIcon from "./images/arrow.webp";
import { GA_EVENTS } from "./tracking";
import { computeTimeDifferenceInSeconds } from "../../../utils/helpers/date-and-time";
import getCurrentPage from "../../../utils/helpers/get-current-page";
import CarouselPills from "./carousel-pills";
import isTestDriveEnabledState from "../../../utils/helpers/is-test-drive-enabled-state";
import useUserGeoLocation from "../../../hooks/use-user-geo-location";

const InventoryCarCardRevamp = ({
    booked = false,
    year,
    appointmentId: carId,
    make,
    model,
    bodyType = "",
    price,
    egc,
    variant,
    state,
    fuelType,
    config = {},
    onClickCallback = () => { },
    transmissionType,
    history,
    emiDetails,
    isLoggedIn,
    openLoginPopupConnect,
    setOnLoginSuccessCallback,
    secureToken,
    isWishlistedCar = () => { },
    onClickAddToWishList,
    gtmFrom,
    showWishlist = true,
    showRemoveIcon = false,
    defaultTootlipStyle,
    allFilters,
    appliedFilters,
    item,
    index,
    selectedSort,
    impressionSource,
    comingSoon = false,
    egcRenderType,
    listingActive,
    saveCheckoutSourcePathnameConnect,
    personalised,
    // promotion,
    saleConfig,
    expressDelivery,
    cardType = "COMMON",
    selectedCity = {},
    //handlePriceBreakCallback = () => { },
    handleEcgViewCallback = () => { },
    tradeInToggleSelected,
    userTradeInData,
    kbbAskingPrice,
    email,
    listingType,
    isUserZeroDpVariant,
    ampImpressionSource,
    ampPageSource,
    carCardActionTarget = "_self",
    setLoginTypeConnect,
    preload = false,
    showPriceWithTradeInApplied,
    preventDefaultEvents,
    isGuestLogin,
    guestLoginProps
}) => {
    const [showEcgPrice, setShowEcgPrice] = useState(false);
    const [toolTipTitle, setToolTipTitle] = useState("");
    const [toolTipBody, setToolTipBody] = useState([]);
    const [showKBBModal, setShowKBBModal] = useState(false);
    const [cardImageData, setCardImageData] = useState({ currentIndex: null, timerId: null, stopAnimation: false, startTimestamp: null });
    const searchQuery =  getQueryParamsSearch();
    const userGeoData = useUserGeoLocation();
    const { state: { name: userState}} = userGeoData || {};
    const {saleLive, key: saleKey} = saleConfig?.data || {};
    // let onSale = isSaleActive(promotion, saleConfig);
    const defaultSale =  (price - egc) >= NUMBER.THREE_HUNDRED;
    const isSaleLive = saleLive && defaultSale;
    const cardHoverRef = useRef(null);

    const { carFullName, carImage: imageUrl, kilometerLabel, priceLabel, carAltTag, imagesArray = []  } = getCarInfo(item, config);

    const carName = `${make || ""} ${model || ""}`;
    const comingSoonImage = smallImage[bodyType.toLowerCase()] || smallImage.sedan;
    const { relativeURL, absoluteURL } = carDetailUrlAu({ year, carName, carId });
    const { tradeIn = false } =  userTradeInData || {};
    const { estimatedPrice = 0, kbbValueProvidedOn } = item || {};
    const { isListingPage} = getCurrentPage();

    const kbbMarketPrice = kbbAskingPrice - egc;
    const showKbbMarketPrice = egc < kbbAskingPrice && kbbMarketPrice >=  NUMBER.FIVE_HUNDRED &&
    kbbMarketPrice < NUMBER.TEN_THOUSAND ? kbbMarketPrice : false;
    const showCardBasedOnToggle =  tradeIn && tradeInToggleSelected && estimatedPrice;
    const showTestDriveVariant =  isTestDriveEnabledState(state, userState);
    const {
        emiPerWeek = 0,
        defaultDownPayment
    } = emiDetails || {};

    const handleGetPreApprovalClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push("/financing/get-pre-approval");
    };

    const BottomLink = () => {
        return (
            <div
                styleName={"styles.bottomLink"}>
                <span>Want to know your exact finance rates?</span>
                <Button text="GET PRE-APPROVAL" leftImage={WhiteTickIcon} onClick={handleGetPreApprovalClick} btnView="new" />
                <small>No impact on your credit score</small>
            </div>
        );
    };

    useEffect(() => {
        if (personalised) {
            setToolTipTitle(`Weekly payment of ${makePriceLabelRound(emiPerWeek)}`);
            setToolTipBody(getPersonalisedTooltipInfoList(emiDetails));
        } else {
            setToolTipTitle(`Weekly payment of ${makePriceLabelRound(emiPerWeek)}`);
            setToolTipBody(getTooltipInfoList(emiDetails, isUserZeroDpVariant).concat(<BottomLink />));
        }
    }, [emiPerWeek, emiDetails, personalised, isUserZeroDpVariant]);

    const filterDimensions = parseFilterToDimension(allFilters, appliedFilters, selectedSort);
    const filterDimensionsGA4 = parseFilterToDimensionGA4(allFilters, appliedFilters, selectedSort);

    const ecommerEventData = () => {
        const data = carListingEventDataParser(item, {
            position: index + 1,
            list: impressionSource,
            filterDimensions
        });
        return data;
    };

    const eventData = () => {
        const data = ecommerEventData();
        // trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_DISCOUNT_VISIBLE,{eventLabel:})
        return {
            name: AU_DESKTOP_EVENTS.CAR_IMPRESSION,
            data: {
                ...data.carsImpression
            }
        };
    };

    const eventDataGA4 = () => {
        const dataGA4 = carListingEventDataParserGA4(item, {
            position: index + 1,
            listingType,
            filterDimensions: filterDimensionsGA4
        });
        return {
            name: AU_DESKTOP_EVENTS.VIEW_ITEM_LIST,
            data: {
                user_id: email,
                ...dataGA4
            }
        };
    };

    const amplitudeEvent = () => {
        const {carListing} = carListingAmplitude(item, {
            position: index + 1,
            list: ampImpressionSource
        });
        return carListing;
    };

    const amplitudeEventImpression = () => {
        const {impressions} = amplitudeEvent();
        return {
            name: AMPLITUDE_EVENTS_CATEGORY.CAR_CARDS_IMPRESSION_IN_BATCH,
            data: impressions
        };
    };

    const trackCardClickEvent = () => {
        if (preventDefaultEvents) return;
        const data = carListingEventDataParser(item, { position: index + 1, list: impressionSource, filterDimensions});
        const dataGA4 = carListingEventDataParserGA4(item, { filterDimensions: filterDimensionsGA4, listingType, position: index + 1});
        trackDesktopCustomEventsAU("clearEcommerce", { ecommerce: null });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SELECT_ITEM, { user_id: email, ...dataGA4});
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CAR_CLICK, data.carClick);
        if (cardType === SPECIALPAGETYPE.LOCATION_BASED) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE, {
                eventAction: `car_listing_card_open_${(selectedCity || {}).name}`,
                eventLabel: "{{view_details}}"
            });
        }
        if (searchQuery) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE, {
                eventAction: "search_result_click",
                eventLabel: `Click Position: ${index + 1} Appointment ID: ${carId}`
            });
        }
    };

    const onCardClickHandler = (e) => {
        e.preventDefault();
        onClickCallback(item);
        trackCardClickEvent();
        history.push(relativeURL, { persistBackScroll: true });

    };

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onClickWishlistGAEvent = () => {
        if (cardType === SPECIALPAGETYPE.LOCATION_BASED) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS24_AU_ALL_PAGES, {
                eventAction: `wishlist_cta_selected_${(selectedCity || {}).name}`,
                eventLabel: "listing_page"
            });
        }
    };

    const onWishlistLoginSuccess = (resp, id) => {
        const { access_token: token } = resp;
        const {addtoWishList} = ecommerEventData();
        onClickWishlistGAEvent();
        onClickAddToWishList(id, token, gtmFrom, { comingSoon, make, model });
        trackDesktopCustomEventsAU(FB_EVENTS_CATEGORY.ADD_TO_WISHLIST, {content: addtoWishList});
    };

    const onClickWishlist = (e) => {
        const {addtoWishList} = ecommerEventData();
        if (e) { preventPropagation(e); }
        if (isLoggedIn) {
            onClickWishlistGAEvent();
            onClickAddToWishList(carId, secureToken, gtmFrom, { comingSoon, make, model });
            trackDesktopCustomEventsAU(FB_EVENTS_CATEGORY.ADD_TO_WISHLIST, {content: addtoWishList});
        } else {
            openLoginPopupConnect();
            setLoginTypeConnect(LOGIN_TYPE.WISHLIST);
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LOGIN_INITIATED, {eventLabel: LOGIN_SOURCE.WISHLIST});
            setOnLoginSuccessCallback((resp) => onWishlistLoginSuccess(resp, carId));
        }
    };

    const comingSoonInfo = (e) => {
        preventPropagation(e);
        window.open(`${cmsURl()}/pages/coming-soon`, "_blank");
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.COMING_SOON_LISTING_INFO);
    };

    // const handlePriceBreak = () => {
    //     setShowPriceBreak(!showPriceBreak);
    //     handlePriceBreakCallback(!showPriceBreak);
    // };

    const getStarted = () => {
        saveCheckoutSourcePathnameConnect(history.location.pathname);
        const { relativeURL: checkoutPageUrl } = getCheckoutPageURL({ make, model, appointmentId: carId }, checkoutRoutes.basicDetails.route);
        if (isLoggedIn) {
            history.push(checkoutPageUrl);
        } else {
            openLoginPopupConnect();
            setOnLoginSuccessCallback(() => history.push(checkoutPageUrl));
        }
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.STATE_WISE_PRICE_BREAKDOWN_GET_STARTED, {
            eventCategory: "Cars24_listing_page"
        });
    };

    const handleEcgView = () => {
        if (!showEcgPrice) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.STATE_WISE_PRICING_INFO_ICON);
        }
        setShowEcgPrice(!showEcgPrice);
        handleEcgViewCallback(!showEcgPrice);
    };

    const openEgcView = (e) => {
        preventPropagation(e);
        if (!showEcgPrice) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.STATE_WISE_PRICING_INFO_ICON);
        }
        setShowEcgPrice(!showEcgPrice);
        handleEcgViewCallback(!showEcgPrice);
    };

    const getCta = () => {
        const cta = { text: "GET STARTED", action: getStarted, disabled: false };
        cta.disabled = (comingSoon || booked || !listingActive);
        return cta;
    };

    const handleOpenMarketModal = (e) => {
        preventPropagation(e);
        if (!showKBBModal) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_BELOW_MARKET_CLICKED, {eventLabel: `${showKbbMarketPrice}_${carId}`});
        }
        setShowKBBModal(!showKBBModal);
    };

    const { showExpressTag, days: expressDeliveryDate } = expressDelivery || {};

    const showDefaultDiscount = defaultSale && saleKey !== SALE_KEY.BLACK_FRIDAY && saleKey !== SALE_KEY.CHRISTMAS_SALE;

    const handleRepaymentInfoClick = (isOpen) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.INFO_ICON_REPAYMENT, {
            eventLabel: `Repayment_icon_${gtmFrom}`,
            eventAction: isOpen ? "Repayment_icon_opened" : "Repayment_icon_closed"
        });
    };

    // eslint-disable-next-line consistent-return
    const cardClickWrapper = (e) => {
        e.preventDefault();
        if (ampPageSource !== PAGE_SOURCE.SELL_VALUATION_PAGE || isLoggedIn) {
            return onCardClickHandler(e);
        }
        if (isGuestLogin) {
            const { setOnGuestEmailVerifyCallback, openGuestLoginConnect} = guestLoginProps;
            openGuestLoginConnect(GUEST_POPUP_TYPES.VERIFY);
            setOnGuestEmailVerifyCallback(() => onCardClickHandler(e));
        } else {
            openLoginPopupConnect();
            setOnLoginSuccessCallback(() => onCardClickHandler(e));
        }
    };
    const updateState = (newState) => {
        setCardImageData((prevState) => ({ ...prevState, ...newState }));
    };

    useEffect(() => {
        if (cardImageData.stopAnimation) {
            window.clearInterval(cardImageData.timerId);
            updateState({ currentIndex: 0, stopAnimation: false });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardImageData.stopAnimation]);

    const handleMouseEnter = () => {
        if (!cardHoverRef.current) {
            cardHoverRef.current = true;
        }
        if (!imagesArray?.length || !isListingPage) {
            return;
        }

        setCardImageData((prevState) => {
            return {
                ...prevState, startTimestamp: new Date().getTime()
            };
        }
        );
        const timer = window.setInterval(() => {
            setCardImageData((prevState) => {
                if (prevState.currentIndex === imagesArray.length - 1) {
                    return {
                        ...prevState,
                        stopAnimation: true,
                        currentIndex: 0
                    };
                }
                return {
                    ...prevState,
                    currentIndex: (prevState.currentIndex + 1) % imagesArray.length
                };
            });
        }, NUMBER.SEVEN_HUNDRED);
        updateState({ timerId: timer });
    };
    const handleMouseLeave = () => {
        const startTime = cardImageData.startTimestamp;
        const timeDiff = computeTimeDifferenceInSeconds(new Date().getTime(), startTime);
        const numberOfImagesSeen = cardImageData.currentIndex === 0 && !cardImageData.stopAnimation ?
            imagesArray.length : cardImageData.currentIndex + 1;

        if (timeDiff > NUMBER.DECIMAL_FIVE && startTime > 0) {
            trackDesktopCustomEventsAU(GA_EVENTS.CAR_CARD_HOVER_ENDED_TIME, {
                ...GA_EVENTS.CAR_CARD_HOVER_ENDED_TIME_DATA,
                eventLabel: `${timeDiff}sec`
            });

            trackDesktopCustomEventsAU(GA_EVENTS.CAR_CARD_HOVER_CARDS_SEEN, {
                ...GA_EVENTS.CAR_CARD_HOVER_CARDS_SEEN_DATA,
                eventLabel: numberOfImagesSeen
            });
        }
        updateState({ currentIndex: null, stopAnimation: false, startTimestamp: null });
        window.clearInterval(cardImageData?.timerId);
        return null;

    };

    return (
        <TrackableCarCardItem event={eventData()} eventGA4={eventDataGA4()} eventAmplitude={amplitudeEventImpression()}>
            <div>
                {preload &&
                    <Helmet>
                        <link rel="preload" href={imageUrl} as={"image"} type="image/jpg" fetchPriority="high" />
                    </Helmet>
                }
                <div>
                    <span>
                        <meta content="AUD" />
                        <meta content={price && parseInt(price)} />
                    </span>
                </div>
                <React.Fragment>
                    <a className="invCarCard" styleName={"styles.carCard"} href={absoluteURL} onClick={cardClickWrapper} target={carCardActionTarget}>
                        {showTestDriveVariant && !item.reserved && <div styleName={"styles.testDriveBadgeWrapper"}>
                            <img src={TestDriveBadge} />
                            <p styleName={"styles.testDrive"}>Test drive</p>
                        </div>}
                        <div className={"align-self-center"} styleName={"styles.carImage"}>
                            {comingSoon ?
                                <div styleName="styles.comingSoonSection">
                                    <img styleName={"styles.imageComingSoon"} alt={carAltTag} src={comingSoonImage} />
                                    <div styleName={"styles.comingSoonWrap"}>
                                        <img styleName={"styles.comingSoonImg"} src={ComingSoon} alt="Coming Soon" />
                                        <span styleName={"styles.whatText"}>
                                            <button onClick={comingSoonInfo}>
                                                <img src={InfoIcon} /> <small styleName={"styles.showText"}>What does this mean?</small>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                :
                                <div styleName={"styles.imageWrapper"}>
                                    {(cardImageData.currentIndex === 0 && !cardImageData.stopAnimation) && <div styleName={"styles.overlay"} >
                                        <p styleName={"styles.overlayText"}>View more images <img src={ArrowIcon} /></p>
                                    </div>}
                                    {isListingPage && <CarouselPills cardImageData={cardImageData} activeIndex={cardImageData.currentIndex} />}
                                    {index < NUMBER.THREE ?
                                        <img
                                            styleName={`styles.mainImage`}
                                            alt={carAltTag}
                                            src={(imagesArray?.length && imagesArray[cardImageData?.currentIndex || imageUrl]) || imageUrl}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        : <LazyImage styleName={"styles.mainImage"} alt={carAltTag} src={(imagesArray?.length && imagesArray[cardImageData?.currentIndex || imageUrl]) || imageUrl}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                                    }
                                    {item.threeSixtyViewAvailable && <img styleName={"styles.threeSixtyImage"} src={ThreeSixtyDegree} alt={"threeSixty"} />}
                                    {showDefaultDiscount &&
                                        <p styleName={"styles.discountBanner"}><img src={DiscountIcon} />
                                            {makePriceLabelRound(price - egc)} off
                                        </p>
                                    }
                                    {isSaleLive && saleKey === SALE_KEY.BLACK_FRIDAY &&
                                        <div styleName={"styles.blackFridaySaleWrap"}>
                                            <p styleName={"styles.blackFridaySale"}>SAVE
                                                <span>&nbsp;{makePriceLabelRound(price - egc)}</span></p>
                                            <img styleName={"styles.blackFridaySubHeading"} src={EndToday} />
                                        </div>
                                    }
                                    {isSaleLive && saleKey === SALE_KEY.CHRISTMAS_SALE &&
                                        <div styleName={"styles.christmasSaleWrap"}>
                                            <p styleName={"styles.christmasSale"}>SAVE
                                                <span>&nbsp;{makePriceLabelRound(price - egc)}</span></p>
                                            <p styleName={"styles.christmasSaleSubheading"}>12 Cars. 12 Deals.</p>
                                        </div>
                                    }
                                    {item.reserved && !item.booked && <div styleName={"styles.this_car"}>
                                        <img src={Lockimg} alt={Lockimg} />
                                        <p>RESERVED</p>
                                    </div>}
                                </div>

                            }
                            {booked || !listingActive ? <div styleName={"styles.carSoldWrapper"}>
                                <LazyImage src={SoldBadge} alt="Sold Out" />
                            </div> : null}
                            {showRemoveIcon &&
                                <div styleName={"styles.closeIcon"} onClick={onClickWishlist}>
                                    <CloseButton
                                        type="white"
                                        source="wishlist"
                                    />
                                </div>}
                        </div>
                        <div styleName={`styles.outer`}>
                            <div styleName={"styles.carNameWrap"}>
                                <h2 styleName={"styles.carNameHeading"}>
                                    {carFullName}
                                </h2>
                                {showWishlist && <div styleName={"styles.wishlistIcon"}>
                                    <IconHeart
                                        active={isWishlistedCar(carId)}
                                        onClick={onClickWishlist}
                                        source="wishlist"
                                    />
                                </div>
                                }
                                <p styleName={"styles.modelName"}>
                                    {variant}
                                </p>
                            </div>
                            <div styleName={"styles.carTypeDeliveryWrap"}>
                                <ul styleName={"styles.carType"}>
                                    {kilometerLabel && <li>{kilometerLabel}</li>}
                                    {transmissionType && <li>{transmissionType}</li>}
                                    {fuelType && <li>{fuelType}</li>}
                                </ul>
                                {showExpressTag && <p styleName={"styles.delivery"}>
                                    <img src={Truck} alt="Delivery Image" />
                                    {expressDeliveryDate} day delivery
                                </p>}
                                {/* <p styleName={"styles.delivery"}>FASTER DELIVERY</p> */}
                            </div>
                            {/* Price section */}
                            {cardType === SPECIALPAGETYPE.COMMON ?
                                <div styleName={"styles.priceWrap"}>
                                    { ((tradeIn && tradeInToggleSelected) || showPriceWithTradeInApplied) && (
                                        <div>
                                            <p styleName="styles.tradeInValue">Without trade-in: <span>{priceLabel}</span>
                                                { defaultSale && <span styleName="styles.tradeInPriceStrike">{makePriceLabelRound(price)}</span> }
                                            </p>
                                        </div>
                                    )}
                                    { (showCardBasedOnToggle || showPriceWithTradeInApplied) ?
                                        <div styleName={"styles.carPrice-new"}>
                                            <div styleName={"styles.shippingDetail-new"}>
                                                <button onClick={openEgcView}>Excl. Govt. Charges <img src={InfoBlue} alt="Weekly Payment" /></button>
                                            </div>
                                            <div styleName={"styles.text-align"}>
                                                <div styleName={"styles.ecgWrapper"}>
                                                    <strong styleName="styles.tradeInEstimatePrice">{makePriceLabelRound(estimatedPrice)}</strong>
                                                </div>
                                            </div>
                                        </div> :
                                        <div styleName={"styles.carPrice"}>
                                            <div>
                                                <strong styleName={"styles.carPriceValue styles.tooltipHolderLeft"}>
                                                    {/* {personalised && <h2 styleName="styles.fromPlaceholder">From</h2>} */}
                                                    {makePriceLabelRound(emiPerWeek)}/week
                                                    <Tooltip
                                                        customStyleHeading={true}
                                                        heading={toolTipTitle}
                                                        body={toolTipBody}
                                                        defaultTootlipStyle={defaultTootlipStyle}
                                                        trackEvents={handleRepaymentInfoClick}
                                                    >
                                                        <img src={InfoOutline} alt="Weekly Payment" />
                                                    </Tooltip>
                                                </strong>
                                                {/* <h6 styleName={"styles.comparisonRate"}>{comparisonRate || ""}% comparison rate | {makePriceLabelRound(depositAmountToShow)} deposit</h6> */}
                                                {defaultDownPayment === NUMBER.ZERO &&  <h6 styleName={"styles.zeroDeposit"}>$0 deposit</h6>}
                                            </div>
                                            <div styleName={"styles.text-align"}>
                                                {/* {defaultSale && (
                                                    <div styleName="styles.saleStrikeCtn">
                                                        <span>{makePriceLabelRound(price)}</span>
                                                    </div>
                                                )} */}
                                                <div styleName={"styles.ecgWrapper"}>
                                                    {defaultSale && !(tradeIn && tradeInToggleSelected) && (
                                                        <span styleName="styles.saleStrikeCtn">{makePriceLabelRound(price)}</span>
                                                    )}
                                                    <strong styleName="styles.tradeInEstimatePrice">
                                                        { (tradeIn && tradeInToggleSelected) ? makePriceLabelRound(estimatedPrice) : priceLabel }
                                                    </strong>
                                                </div>
                                                <div styleName={"styles.shippingDetail"}>
                                                    <button onClick={openEgcView}>Excl. Govt. Charges <img src={InfoBlue} alt="Weekly Payment" /></button>
                                                </div>
                                                {showKbbMarketPrice &&
                                                    <div styleName={"styles.stickerContainer"}>
                                                        <div styleName={"styles.stickerWrapper"}>
                                                            <img src={TrendingImage} />
                                                            <p styleName={"styles.marketPrice"}>{makePriceLabelRound(showKbbMarketPrice)}</p>
                                                            <button styleName={"styles.belowMarket"} onClick={handleOpenMarketModal}>BELOW MARKET*</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                :
                                <div>
                                    <Button text="view details" btnView="new" />
                                </div>

                            }
                        </div>

                    </a>

                </React.Fragment>
                {showEcgPrice &&
                    <EGCPriceView
                        {...item}
                        onClose={handleEcgView}
                        config={config}
                        type={egcRenderType}
                        bodyType={item.body || item.bodyType}
                        cta={getCta()}
                    />
                }
                {/* {showPriceBreak &&
                    <PriceBreakDown
                        onClose={handlePriceBreak}
                        imageHost={config.imageHost}
                        egcData={[egcData]}
                        cta={getCta()}
                    />} */}
                {showKBBModal &&
                <KbbPriceScreen
                    onClose={handleOpenMarketModal}
                    egc={egc}
                    kbbAskingPrice={kbbAskingPrice}
                    kbbValueProvidedOn={kbbValueProvidedOn}
                />}
            </div>
        </TrackableCarCardItem>
    );
};

InventoryCarCardRevamp.propTypes = {
    booked: PropTypes.bool,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modelYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    appointmentId: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    price: PropTypes.number,
    odometerReading: PropTypes.number,
    fuelType: PropTypes.string,
    mainImage: PropTypes.object,
    isUserZeroDpVariant: PropTypes.bool,
    selectedCity: PropTypes.object,
    config: PropTypes.object,
    onClickCallback: PropTypes.func,
    transmissionType: PropTypes.string,
    variant: PropTypes.string,
    history: PropTypes.object,
    emiDetails: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    openLoginPopupConnect: PropTypes.func,
    specialPageType: PropTypes.string,
    setOnLoginSuccessCallback: PropTypes.func,
    secureToken: PropTypes.string,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    gtmFrom: PropTypes.string,
    showWishlist: PropTypes.bool,
    showRemoveIcon: PropTypes.bool,
    defaultTootlipStyle: PropTypes.string,
    allFilters: PropTypes.object,
    appliedFilters: PropTypes.array,
    item: PropTypes.object,
    index: PropTypes.number,
    impressionSource: PropTypes.string,
    selectedSort: PropTypes.object,
    comingSoon: PropTypes.bool,
    bodyType: PropTypes.string,
    handleEcgView: PropTypes.func,
    handlePriceBreakCallback: PropTypes.func,
    handleEcgViewCallback: PropTypes.func,
    fetchCarDetailsConnect: PropTypes.func,
    egcRenderType: PropTypes.bool,
    egc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    listingActive: PropTypes.bool,
    saveCheckoutSourcePathnameConnect: PropTypes.func,
    personalised: PropTypes.bool,
    promotion: PropTypes.bool,
    saleConfig: PropTypes.object,
    threeSixtyViewUrl: PropTypes.string,
    mediaSource: PropTypes.string,
    expressDelivery: PropTypes.object,
    cardType: PropTypes.string,
    tradeInToggleSelected: PropTypes.bool,
    userTradeInData: PropTypes.object,
    kbbAskingPrice: PropTypes.number,
    email: PropTypes.string,
    listingType: PropTypes.string,
    amplitudeEventName: PropTypes.string,
    ampImpressionSource: PropTypes.string,
    ampPageSource: PropTypes.string,
    carCardActionTarget: PropTypes.string,
    state: PropTypes.string,
    setLoginTypeConnect: PropTypes.func,
    ampProperty: PropTypes.object,
    preload: PropTypes.bool,
    showPriceWithTradeInApplied: PropTypes.bool,
    preventDefaultEvents: PropTypes.bool,
    isGuestLogin: PropTypes.bool,
    guestLoginProps: PropTypes.object
};

export default withLogin(withWishlist(InventoryCarCardRevamp));
