import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { NUMBER } from "../../../constants/app-constants";

// l - large, m - medium, s - small
const DEFAULT_PILL_CONFIG = {
    0: "lllms",
    1: "lllms",
    2: "smlms",
    3: "smlll",
    4: "smlll"
};

const CarouselPills = ({activeIndex = 0, cardImageData}) => {
    const currentIndex = (cardImageData.currentIndex === 0 && !cardImageData.stopAnimation) ? NUMBER.FOUR : activeIndex || 0;
    const {timerId} = cardImageData;
    return (
        <div styleName="styles.carouselPills">
            {(DEFAULT_PILL_CONFIG[currentIndex || 0]?.split("") || []).map((pill, index) => (
                <div key={`${pill}-${index}-${timerId}`} styleName={`styles.pill styles.${[pill]} ${index === currentIndex ? "styles.active" : ""}`} />
            ))}
        </div>
    );
};

CarouselPills.propTypes = {
    cardImageData: PropTypes.object,
    activeIndex: PropTypes.number
};

export default CarouselPills;
