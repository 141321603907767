import {NUMBER} from "../../constants/app-constants";

export const converMilliToTime = (duration) => {
    let minutes = parseInt((duration / (NUMBER.THOUSAND * NUMBER.SIXTY)) % NUMBER.SIXTY);
    let hours = parseInt((duration / (NUMBER.THOUSAND * NUMBER.SIXTY * NUMBER.SIXTY)));

    if (hours < 0) {hours = 0;}
    if (minutes < 0) {minutes = 0;}

    hours = (hours < NUMBER.TEN) ? `0${  hours}` : hours;
    minutes = (minutes < NUMBER.TEN) ? `0${  minutes}` : minutes;

    return `${hours  }:${  minutes  }`;
};

// Calculates the time difference between a given date and time and the current date and time.
export const getTimeDifference = (date, time) => {
    if (!date || !time) {
        return -1;
    }
    const currentDate = new Date();
    let dateString = `${date} ${time}`;
    dateString = dateString.replaceAll("-", "/");
    const parsedDate = new Date(dateString).getTime();
    const timeDifference = parsedDate - currentDate;
    return timeDifference;
};

export const computeTimeDifferenceInSeconds = (timestamp1, timestamp2) => {
    const differenceInMillis = Math.abs(timestamp1 - timestamp2);
    const differenceInSeconds = differenceInMillis / NUMBER.THOUSAND;
    return differenceInSeconds;
};
